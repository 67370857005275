import React from "react";
import Footer from "src/components/Footer_gr";


class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
    </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/cookie-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/cookie-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-cookies/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politicia-de-cookies/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/cookie-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/cookie-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h1>Πολιτική για τα Cookies</h1>
                <p> </p>
                <p>
                Αυτή η Πολιτική για τα Cookies έχει σχεδιαστεί για να σας ενημερώσει για τις πρακτικές μας σχετικά με τη συλλογή πληροφοριών μέσω των cookies και άλλες τεχνολογίες εντοπισμού (όπως gifs, web beacons κλπ).
                </p>
                <p>
                Οι λειτουργίες για τις οποίες χρησιμοποιούμε αυτές τις τεχνολογίες μπορεί να περιλαμβάνουν:
                </p>
                <p> </p>
                <ul>
                  <li>Τη συνδρομή του χρήστη στην περιήγηση</li>
                  <li>
                    Τη συνδρομή στην εγγραφή στις εκδηλώσεις μας, στη σύνδεση
                    (login) και στη δυνατότητά σας να σχολιάζετε
                  </li>
                  <li>
                    Την ανάλυση της χρήσης των προϊόντων μας, των υπηρεσιών ή
                    των εφαρμογών
                  </li>
                  <li>
                    Τη συνδρομή στις προωθητικές και διαφημιστικές προσπάθειες
                    (συμπεριλαμβανομένης της συμπεριφορικής διαφήμισης)
                  </li>
                  <li>
                    Την προσφορά περιεχομένου σε τρίτους (όπως περιεχόμενο μέσων
                    κοινωνικής δικτύωσης)
                  </li>
                </ul>
                <p> </p>
                <p>
                Παρακάτω ακολουθεί ένας λεπτομερής κατάλογος των cookies που χρησιμοποιούμε με περιγραφή. Ταξινομούμε τα cookies στις ακόλουθες κατηγορίες:
                </p>
                <ul>
                  <li>Απολύτως απαραίτητα cookies</li>
                  <li>Cookies Απόδοσης</li>
                  <li>Cookies Λειτουργικότητας</li>
                  <li>Cookies Στόχευσης</li>
                </ul>
                <p>
                Μ εξαίρεση τις περιπτώσεις που επιτρέπεται από το εφαρμοστέο δίκαιο, τοποθετούμε cookies αφού έχουμε λάβει τη συγκατάθεσή σας μέσω του cookie banner ή του κέντρου επιλογών. Μπορείτε να αλλάξετε τις ρυθμίσεις για τα cookies ανά κατηγορία cookies [εκτός από τα απολύτως απαραίτητα cookies που απαιτούνται για την σωστή λειτουργία του ιστότοπου] οποτεδήποτε πατώντας το κουμπί «ρυθμίσεις cookies» παρακάτω:
                </p>

                <p>
                <div>
                  <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Policy</button>
                </div>
                <br/>
              <br/>
              </p>
              <p>
                <div id="ot-sdk-cookie-policy"></div>
                &nbsp;
              </p>
                <h2>Συνδέσεις με άλλους ιστότοπους</h2>
                <p>
                  Αυτός ο ιστότοπος μπορεί να περιλαμβάνει συνδέσεις ή αναφορές
                  σε άλλους ιστότοπους. Θα θέλαμε να σημειώσουμε ότι δεν
                  ελέγχουμε τα cookies/ τις τεχνολογίες εντοπισμού άλλων
                  ιστότοπων και αυτή η Πολιτική σχετικά με Cookies δεν θα ισχύει
                  σε αυτούς τους ιστότοπους.
                </p>
                <h2>Πώς να επικοινωνήσετε μαζί μας</h2>
                <p>
                  Αν έχετε οποιεσδήποτε ερωτήσεις, σχόλια ή ανησυχίες σχετικά με
                  αυτήν την Πολιτική για τα Cookies ή άλλες πρακτικές
                  πληροφοριών αυτού του ιστότοπου, παρακαλούμε όπως
                  χρησιμοποιήσετε τα στοιχεία επικοινωνίας στην Πολιτική
                  Προστασίας Προσωπικού Απορρήτου
                </p>
                <h2>Τροποποιήσεις αυτής της Πολιτικής για τα Cookies</h2>
                <p>
                  Αν αυτή η Πολιτική για τα Cookies τροποποιηθεί, η
                  τροποποιημένη πολιτική θα δημοσιευθεί σε αυτόν τον ιστότοπο.
                </p>
                <p>
                  Αυτή η Πολιτική για τα Cookies τροποποιήθηκε για τελευταία
                  φορά στις 4/04/2022.
                </p>
                <p> </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
